<template>
  <div class="added-media-to-campaign">
    <b-card class="added-media-card mt-1rem text-center green flex-grow-1 flex-shrink-0 overflow-auto">
      <b-row>
        <b-col
          cols="12"
          md="6"
        >
          <div class="d-flex align-items-center justify-content-start sub-title">
            Duration Limit: {{ maxTimeLimit }}sec(Max)
          </div>
        </b-col>
      </b-row>
      <div
        v-if="addedMedia.length !== 0"
        class="d-flex align-items-center justify-content-start mt-1 ml-2 sub-title"
      >
        Added Files
      </div>
      <b-row class="m-0">
        <div
          v-for="(media,index) in addedMedia"
          :key="index"
          class="d-flex"
        >
          <b-media
            vertical-align="center"
            class="media-file text-truncate"
          >
            <template #aside>
              <div style="position: relative">
                <b-img
                  :src="getThumbnailOfFile(media)"
                  class="image"
                  rounded
                />
                <span class="cross">
                  <b-img
                    :src="require('@/assets/images/simiicons/close.svg')"
                    rounded
                    alt="data.json"
                    class="cursor-pointer"
                    @click="$emit('removeMediaFromList', index)"
                  />
                </span>
              </div>
            </template>
            <b-row>
              <b-col class="col-12 w-2">
                <span
                  class="text float-left text-truncate"
                >
                  {{ media.name }}
                </span>
              </b-col>
              <b-col class="col-12">
                <div class="float-left">
                  <b-img
                    width="15px"
                    height="15px"
                    :src="media.type_main === MediaType.IMAGE ?require('@/assets/images/simiicons/Image(1).svg'):require('@/assets/images/simiicons/Video(1).svg')"
                  />
                </div>
              </b-col>
            </b-row>
          </b-media>
        </div>
      </b-row>
      <div class="d-flex justify-content-end align-items-end">
        <span class="duration">Duration : {{ addedFilesDuration }} sec</span>
      </div>
    </b-card>
  </div>
</template>
<script>
import {
  BMedia, BImg, BRow, BCol, BCard,
} from 'bootstrap-vue'
import MediaType from '@/common/enums/mediaTypeEnum'
import { getThumbnailOfFile } from '@/common/global/functions'

export default {
  name: 'AddedMediaToCampaign',
  components: {
    BMedia,
    BImg,
    BRow,
    BCol,
    BCard,
  },
  inheritAttrs: true,
  props: {
    addedMedia: {
      type: Array,
      required: true,
    },
    maxTimeLimit: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      MediaType,
      durationTotal: 0,
    }
  },
  computed: {
    addedFilesDuration() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.durationTotal = 0
      this.addedMedia.forEach(({ duration }) => {
        this.durationTotal += duration
      })
      return this.durationTotal
    },
  },
  methods: {
    getThumbnailOfFile(file) {
      return getThumbnailOfFile(file)
    },
  },
}
</script>

<style lang="scss">
.added-media-to-campaign{
  .duration{
    font-size: 10px;
    line-height: 18px;
    color: #323232;
    font-weight: 600;
    font-family: "Montserrat",sans-serif;
  }
  .media-file{
    width: 151px;
    height: 60px;
    border-radius: 10px;
    padding-left: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .added-media-card{
    border-radius: 10px;
    background-color: #F2FFFF;
    height: 180px;
    overflow-y: scroll;
    min-height: 150px;
    opacity: 6;
  }
  .sub-title{
    font-size: 10px;
    line-height: 18px;
    color: #323232;
    font-weight: 600;
    font-family: "Montserrat",sans-serif;
  }
  .cross{
    position: absolute;
    top: -7px;
    left: -5px;
  }
  .image {
    width: 32px !important;
    height: 32px !important;
    border: 2px solid#ececec;
    border-radius: 5px !important;
    box-shadow: 0 1px 7px#0000002e;
  }
}
</style>
