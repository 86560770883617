<template>
  <div class="create-target-ad">
    <b-card>
      <div class="heading">
        <p>Add Target Ad</p>
      </div>
      <!-- form -->
      <validation-observer ref="addTargetAd">
        <b-form
          class="mt-2"
          style="width: 100%"
          @submit.prevent
        >
          <b-col
            md="6"
            class="mx-auto"
          >
            <b-form-group
              text-bolder
              label="Target Ad Name"
              label-for="blog-edit-title"
              class="mb-2 campaign-label"
            >
              <validation-provider
                v-slot="{ errors }"
                name="Target Ad Name"
                rules="required"
              >
                <b-form-input
                  id="blog-edit-title"
                  v-model="postData.name"
                  placeholder="Please enter Target Ad name here"
                  style="height: 42px"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-row class="mb-2">
              <b-col
                id="calender"
                cols="6"
              >
                <b-form-group
                  text-bolder
                  label="Date"
                  label-for="Date"
                  class="campaign-label"
                >
                  <validation-provider
                    name="Date"
                  >
                    <b-form-datepicker
                      v-model="dateTimeSelectedFromSelectors.date"
                      placeholder="Choose a date"
                      locale="en"
                      :date-disabled-fn="disabledDates"
                      :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
                      @input="dateChangeHandler()"
                    />
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                id="time"
                cols="6"
              >
                <!--                <b-tooltip-->
                <!--                  v-if="dateTimeSelectedFromSelectors.time && dateTimeSelectedFromSelectors.time.HH && dateTimeSelectedFromSelectors.time.mm "-->
                <!--                  target="tooltip-user-time"-->
                <!--                >-->
                <!--                  <span>Your time will be <br> {{ dateTimeSelectedFromSelectors | momentDateForLocalTime }}</span>-->
                <!--                </b-tooltip>-->
                <b-form-group
                  text-bolder
                  label="Time"
                  label-for="Time"
                  class="campaign-label mb-0"
                >
                  <vue-timepicker
                    id="tooltip-user-time"
                    v-model="dateTimeSelectedFromSelectors.time"
                    :disabled="!dateTimeSelectedFromSelectors.date"
                    input-class="custom-picker"
                    format="HH:mm UTC"
                    :close-on-complete="true"
                    :minute-interval="5"
                    :hour-range="enabledHours"
                    :minute-range="enabledMinutes"
                    @change="timeChangeHandler()"
                  />
                </b-form-group>
                <span
                  v-if="dateTimeSelectedFromSelectors.time && dateTimeSelectedFromSelectors.time.HH && dateTimeSelectedFromSelectors.time.mm "
                  style="font-size: 9.5px; color: #1f58b5; font-weight: bold"
                >Ad date & time as per your timezone will be {{ dateTimeSelectedFromSelectors | momentDateForLocalTime }}</span>
              </b-col>
            </b-row>
            <b-form-group
              text-bolder
              label="Budget"
              label-for="budget"
              class="mb-2 campaign-label"
            >
              <b-input-group>
                <b-input-group-prepend is-text>
                  $
                </b-input-group-prepend>
                <cleave
                  id="budget"
                  v-model="postData.budget"
                  class="form-control"
                  :raw="false"
                  style="height: 42px"
                  :options="number"
                  autocomplete="off"
                  placeholder="$2 per device"
                  @input="budgetChangeHandler()"
                />
              </b-input-group>
            </b-form-group>
            <div class="heading">
              <p>
                Target Audience
              </p>
            </div>
            <b-form-group
              text-bolder
              label="Location"
              label-for="blog-map"
              class="campaign-label"
            >
              <validation-provider
                id="blog-map"
                name="Location"
              >
                <b-form-input
                  id="pac-input-create-target-ads"
                  placeholder="Search location here"
                  type="text"
                  autocomplete="off"
                  @change="changeHandler($event)"
                />
                <b-form-group
                  text-bolder
                  label="Radius"
                  label-for="Radius"
                  class="campaign-label mt-2"
                >
                  <v-select
                    v-model="selectedRadius"
                    class="zindex-2"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    select-size="3"
                    label="text"
                    :clearable="false"
                    :searchable="false"
                    :options="options"
                  />
                </b-form-group>
                <b-row class="d-flex justify-content-center">
                  <b-col
                    cols="9"
                  >
                    <div class=" mt-2 mx-4">
                      <b-button
                        variant="primary"
                        block
                        :disabled="addLocationSpinner"
                        class="p-1 font-medium-2"
                        @click="getAndCountOfTheDevicesByArea()"
                      >
                        <div
                          v-if="addLocationSpinner"
                          class="spinner"
                        >
                          <b-spinner
                            small
                          />
                        </div>
                        Fetch Devices
                      </b-button>
                    </div>
                  </b-col>
                </b-row>
                <br>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-form>
        <b-row class="d-flex justify-content-center mb-2">
          <b-col
            cols="6"
            class="d-flex justify-content-center"
          >
            <span
              v-if="totalDevices > 0 "
              class="campaign-label font-medium-1 d-flex justify-content-center align-items-center"
            >{{ totalDevices }} devices found in this radius & approximate cost would be ${{ totalDevices*2 }} !</span>
          </b-col>
        </b-row>
      </validation-observer>
      <b-skeleton-img
        v-if="mapLoading"
        height="75%"
      />
      <MapView
        v-else
        id="pac-input-create-target-ads"
        ref="map-view-id"
        :lat="initialLocation.lat"
        :lng="initialLocation.lng"
        :zoom-level="mapZoomLevel"
        @circleCenterSetFunc="circleCenterSetFunc"
        @devicesGetLatLong="devicesGetLatLong"
        @locationAddressSetFunc="locationAddressSetFunc"
      />
      <!--/ form -->
      <BannerAdsMedia
        :max-time-limit="TimeLimitCondition.maxTargetAdsMediaCondition"
        @addMediaToCampaign="addMediaToTargetAd"
      />
      <AddedMediaToCampaign
        ref="add-media-cam-ref"
        :added-media="addedFilesToCampaign"
        :max-time-limit="TimeLimitCondition.maxTargetAdsMediaCondition"
        @removeMediaFromList="deleteMediaFromTargetAd"
      />
      <div class="d-flex justify-content-center mt-0 ">
        <b-button
          variant="outline-primary"
          class="my-1"
          @click="reviewTargetAdsCampaign"
        >
          Review
        </b-button>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="m-1"
          :disabled="spinner"
          @click="openConfirmationModal"
        >
          <div
            v-if="spinner"
            class="spinner"
          >
            <b-spinner
              small
            />
          </div>
          Create
        </b-button>
        <b-button
          class="choose my-1"
          @click="cancelTargetAds"
        >
          Cancel
        </b-button>
      </div>
    </b-card>
  </div>
</template>
<script>
import {
  BCard,
  BForm,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BSpinner,
  BRow,
  BFormDatepicker,
  BInputGroupPrepend,
  BInputGroup,
  BSkeletonImg,
  VBTooltip,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import moment from 'moment'
import Vue from 'vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import Cleave from 'vue-cleave-component'
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue'
import TimeLimitCondition from '@/common/config'
import BannerAdsMedia from '@/components/common/BannerAdsMedia.vue'
import AddedMediaToCampaign from '@/components/common/AddedMediaToCampaign.vue'
import MapView from '@/components/target-ads/MapView.vue'
import store from '@/store'
import MemberPermissions from '@/common/enums/memberPermissionsEnum'
import { getLowestTimeForTargetAd, showErrorMessage, showToast } from '@/common/global/functions'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'

Vue.prototype.moment = moment
export default {
  name: 'CreateTargetAd',
  components: {
    MapView,
    vSelect,
    BCard,
    BForm,
    BCol,
    BFormGroup,
    BFormInput,
    AddedMediaToCampaign,
    BButton,
    BInputGroupPrepend,
    BInputGroup,
    // BFormTimepicker,
    BRow,
    Cleave,
    BannerAdsMedia,
    BSpinner,
    BFormDatepicker,
    BSkeletonImg,
    VueTimepicker,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  filters: {
    moment(date) {
      return moment(date).format('DD-MM-YYYY h:mm a')
    },
    momentDateForLocalTime(date) {
      return moment.utc(`${date.date} ${date.time.HH}:${date.time.mm}`, 'YYYY-MM-DD HH:mm').local().format('MMM DD,YYYY HH:mm')
    },
  },
  beforeRouteEnter(to, from, next) {
    const memberPermission = store.getters['user/getPermissionOfMember'](MemberPermissions.TARGET_ADS)
    if (!memberPermission) {
      next({
        name: 'dashboard',
      })
    } else { next() }
  },
  data() {
    return {
      mapLoading: false,
      initialLocation: {
        lat: 31.530443164751656, lng: 74.37880084073794,
      },
      mapZoomLevel: 10,
      lowestDateTimeCanBeSelected: {
        dateTime: null,
        date: null,
        time: null,
      },
      dateTimeSelectedFromSelectors: {
        date: null,
        time: null,
      },
      spinner: false,
      addLocationSpinner: false,
      TimeLimitCondition,
      postData: {
        name: null,
        time: null,
        location: {},
        radius: 0,
        target_ads_has_media: [],
        location_address: null,
        budget: null,
        target_ad_budget: 0,
      },
      devicesPaths: [],
      addedFilesToCampaign: [],
      limit: '',
      totalDevices: 0,
      selectedRadius: 0,
      options: [
        { text: ' 5 Km', value: 5 },
        { text: '10 Km', value: 10 },
        { text: '15 Km', value: 15 },
        { text: '20 Km', value: 20 },
      ],
      number: {
        numeral: true,
      },
      required,
    }
  },
  computed: {
    enabledHours() {
      if (this.dateTimeSelectedFromSelectors.date === this.lowestDateTimeCanBeSelected.date) {
        return [[moment(this.lowestDateTimeCanBeSelected.time, 'HH:mm').get('hour'), 23]]
      }
      return [[0, 23]]
    },
    enabledMinutes() {
      if (!this.dateTimeSelectedFromSelectors.time || !this.dateTimeSelectedFromSelectors.time.HH) {
        return [[]]
      }
      let lowestDateTimeCanBeSelectedInRequiredFormat = null
      lowestDateTimeCanBeSelectedInRequiredFormat = moment(this.lowestDateTimeCanBeSelected.time, 'HH:mm').hours()
      if (this.dateTimeSelectedFromSelectors.time && this.dateTimeSelectedFromSelectors.time.HH && this.dateTimeSelectedFromSelectors.date === this.lowestDateTimeCanBeSelected.date && ((`0${lowestDateTimeCanBeSelectedInRequiredFormat}`).slice(-2).toString()) === this.dateTimeSelectedFromSelectors.time.HH.toString()) {
        return [[moment(this.lowestDateTimeCanBeSelected.time, 'HH:mm').get('minutes'), 59]]
      }
      return [[0, 59]]
    },
    isValidDateTimeSelected() {
      if (this.dateTimeSelectedFromSelectors.time && this.dateTimeSelectedFromSelectors.time.HH && this.dateTimeSelectedFromSelectors.time.mm) {
        const selectedDateTime = moment.utc(`${this.dateTimeSelectedFromSelectors.date} ${this.dateTimeSelectedFromSelectors.time.HH}:${this.dateTimeSelectedFromSelectors.time.mm}`, 'YYYY-MM-DD HH:mm')
        return (selectedDateTime.isAfter(this.lowestDateTimeCanBeSelected.dateTime) || selectedDateTime.isSame(this.lowestDateTimeCanBeSelected.dateTime.format()))
      }
      return false
    },
    userIP() {
      return this.$store.getters['user/getUserIP']
    },
  },
  watch: {
    devicesPaths() {
      this.$refs['map-view-id'].devicesMarkerFunc(this.devicesPaths)
    },
    selectedRadius: {
      handler(value) {
        this.$refs['map-view-id'].selectedRadiusFunc(value.value)
        this.postData.radius = value.value
      },
    },
  },
  async mounted() {
    this.updateTimeCanBeSelected()
    await this.updateInitialLocationOnMap()
  },
  methods: {
    timeChangeHandler() {
      this.selectedRadius = 0
      this.devicesPaths = []
      this.totalDevices = 0
    },
    dateChangeHandler() {
      this.selectedRadius = 0
      this.devicesPaths = []
      this.totalDevices = 0
    },
    budgetChangeHandler() {
      this.devicesPaths = []
      this.totalDevices = 0
    },
    disabledDates(ymd, date) { // Will check if the date can be selected or not
      return (moment(date).isBefore(moment(this.lowestDateTimeCanBeSelected.date, 'YYYY-MM-DD')))
    },
    changeHandler(value) {
      this.$refs['map-view-id'].changeHandler()
      this.selectedRadius = 0
      this.postData.radius = 0
      this.totalDevices = 0
      if (!value) {
        this.$refs['map-view-id'].initMap()
        this.postData.location = {}
        this.postData.location_address = null
      }
    },
    openConfirmationModal() {
      this.$refs.addTargetAd.validate().then(async success => {
        if (success) {
          if (this.postData.radius !== 0) {
            if (this.totalDevices === 0) {
              this.$swal('Cannot create with zero devices')
            } else if (!this.addedFilesToCampaign.length <= 0) {
              this.$swal({
                title: '<span class="font-weight-bolder">Book Target Ads ?</span>',
                icon: 'warning',
                html:
                      `You want to book target ads with ${this.totalDevices} devices`,
                showCloseButton: false,
                showCancelButton: true,
                focusConfirm: false,
                confirmButtonText: 'Save',
                confirmButtonAriaLabel: 'Thumbs up, great!',
                cancelButtonAriaLabel: 'Thumbs down',
                customClass: {
                  confirmButton: 'btn btn-primary',
                  cancelButton: 'btn btn-outline-danger ml-1',
                  icon: 'primary',
                },
                buttonsStyling: false,
              })
                .then(result => {
                  if (result.value) {
                    this.createTargetAds()
                  }
                })
            } else {
              showToast('Add Target Ad', 'Cannot create with 0! media files!', 'danger', 4000)
            }
          } else {
            showToast('Add Target Ad', 'Radius should not be Null!', 'danger', 4000)
          }
        }
      })
    },
    updateTimeCanBeSelected() { // will update datetime object according to prior time condition
      const lowestDateTime = getLowestTimeForTargetAd()
      this.lowestDateTimeCanBeSelected.dateTime = lowestDateTime
      this.lowestDateTimeCanBeSelected.date = lowestDateTime.format('YYYY-MM-DD')
      this.lowestDateTimeCanBeSelected.time = lowestDateTime.format('HH:mm')
    },
    async createTargetAds() {
      this.spinner = true
      await this.addNewTargetAds()
      this.spinner = false
    },
    async addNewTargetAds() {
      this.postData.target_ad_budget = Number(this.postData.budget.replaceAll(',', ''))
      this.formatDateTimeInFormData()
      try {
        await this.$axios
          .post('target-ads', this.postData)
        showToast('Add Target Ad', 'Target Ad has been added successfully!')
        await this.$router.push('/target-ads')
      } catch ({
        response: {
          data: {
            statusCode, message,
          },
        },
      }) {
        if (showErrorMessage(statusCode)) {
          showToast('Add Target Ad', message.toString(), 'danger')
        }
      }
    },
    async getAndCountOfTheDevicesByArea() {
      this.formatDateTimeInFormData()
      this.addLocationSpinner = true
      if (!this.isValidDateTimeSelected) {
        showToast('Add Target Ad', 'Please select correct date and time!', 'danger', 4000)
      } else if (Object.keys(this.postData.location).length === 0) {
        showToast('Add Target Ad', 'Please select the location!', 'danger', 4000)
      } else if (this.postData.radius === 0) {
        showToast('Add Target Ad', 'Please select the radius!', 'danger', 4000)
      } else if (this.postData.budget === undefined || !this.postData.budget || this.postData.budget === 0 || this.postData.budget === '0' || Number(this.postData.budget.replaceAll(',', '')) > TimeLimitCondition.maxEstimatedBudget - 1) {
        showToast('Add Target Ad', `Budget range should be $2 to $${TimeLimitCondition.maxEstimatedBudget - 1}`, 'danger', 4000)
      } else if (Number(this.postData.budget.replaceAll(',', '')) <= 1) {
        showToast('Add Target Ad', 'Allocated budget is insufficient for fetching devices!', 'danger', 4000)
      } else {
        try {
          const requestBody = {
            location: this.postData.location,
            radius: this.postData.radius,
            time: this.postData.time,
            budget: Number(this.postData.budget.replaceAll(',', '')),
          }
          const res = await this.$axios
            .post('target-ads/get-available-devices', requestBody)
          const { data: resData } = res || {}
          const { data } = resData || {}
          const {
            deviceArray,
            count,
          } = data || {}
          this.totalDevices = count
          if (this.totalDevices === 0) {
            showToast('Add Target Ad', 'No devices available at this time in this location!', 'danger', 4000)
            await this.devicesGetLatLong(deviceArray)
          }
          await this.devicesGetLatLong(deviceArray)
        } catch ({
          response: {
            data: {
              statusCode,
              message,
            },
          },
        }) {
          if (showErrorMessage(statusCode)) {
            showToast('Add Target Ad', message.toString(), 'danger')
          }
          this.addLocationSpinner = false
        }
      }
      this.addLocationSpinner = false
    },
    circleCenterSetFunc(center) {
      this.postData.location = center
    },
    locationAddressSetFunc(address) {
      this.postData.location_address = address
    },
    devicesGetLatLong(array) {
      this.devicesPaths = []
      const paths = []
      array.forEach(data => {
        paths.push({ lat: data.location.y, lng: data.location.x, name: data.name })
      })
      this.devicesPaths = JSON.parse(JSON.stringify(paths))
      return this.devicesPaths
    },
    cancelTargetAds() {
      this.$router.push('/target-ads')
    },
    updateIdsOfMediaInPayload() {
      this.postData.target_ads_has_media = this.addedFilesToCampaign.map(res => res.id)
    },
    addMediaToTargetAd(media) {
      this.addedFilesToCampaign.push(media)
      this.updateIdsOfMediaInPayload()
    },
    deleteMediaFromTargetAd(index) {
      this.addedFilesToCampaign.splice(index, 1)
      this.updateIdsOfMediaInPayload()
    },
    async reviewTargetAdsCampaign() {
      if (this.addedFilesToCampaign) {
        await this.$store.dispatch('lightBox/lightBoxData', this.addedFilesToCampaign)
      }
    },
    formatDateTimeInFormData() {
      if (this.dateTimeSelectedFromSelectors.date && this.dateTimeSelectedFromSelectors.time && this.dateTimeSelectedFromSelectors.time.HH && this.dateTimeSelectedFromSelectors.time.mm) {
        this.postData.time = moment.utc(`${this.dateTimeSelectedFromSelectors.date} ${this.dateTimeSelectedFromSelectors.time.HH}:${this.dateTimeSelectedFromSelectors.time.mm}`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD[T]HH:mm')
      }
    },
    async updateInitialLocationOnMap() {
      if (this.userIP) {
        try {
          this.mapLoading = true
          // const { data } = await axios.get(`http://ip-api.com/json/${this.userIP}`)
          const { data: { data } } = await this.$axios.get(`user/ip-to-location/${this.userIP}`)
          if (data.longitude && data.latitude) {
            this.initialLocation.lat = Number(data.latitude)
            this.initialLocation.lng = Number(data.longitude)
            this.mapZoomLevel = 10
          }
        } catch (e) {
          //
        } finally {
          this.mapLoading = false
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.create-target-ad{
  .time-picker{
    width: 100%;
    .custom-picker {
      padding: 0.438rem 1rem;
      border: 1px solid #d8d6de;
      border-radius: 0.357rem;
      height: 40px;
      width: 100%;
      font-size: 1.1rem;
      color: #6e6b7b;
      font-weight: 400;
      font-family: "Montserrat";
    }
  }
  #map {
    height: 100%;
  }
  .map-div{
    height: 400px !important;
  }
  .search {
    .vs__dropdown-menu {
      max-height: 80px !important;
      overflow-y: auto !important;
      width: 320px !important;
    }
    .vs__dropdown-toggle{
      max-height: 60px !important;
      overflow-y: auto !important;
      width: 320px !important;
    }
  }
  .vs__search{
    height: 35px !important;
  }
  .v-select.vs--single .vs__selected{
    font-size: 1.1rem;
    color: #6e6b7b;
    font-weight: 400;
    font-family: "Montserrat";
  }
  #form {
    .custom-control {
      min-width: 145px !important;
      max-width: 145px !important;
    }
  }
  .heading{
    font-size: 20px;
    line-height: 44px;
    color: #1f58b5;
    font-weight: 600;
    font-family: "Montserrat",serif;
    text-align: center;
  }
  //.form-control{
  //  height: 42px !important;
  //}
  .campaign-label{
    font-size: 13px;
    line-height: 18px;
    color: #1f58b5;
    font-weight: 600;
    font-family: "Montserrat";
  }
  #calender {
    .dropdown-menu{
      z-index: 12 !important;
    }
  }
  #time{
    .vue__time-picker .controls .char{
      display: none !important;
    }
  }
}
</style>
