<template>
  <div class="map-view-com">
    <div class="position-relative">
      <div
        id="map"
        class="map-div"
      />
    </div>
  </div>

</template>

<script>

export default {
  components: {
  },
  props: {
    id: {
      type: String || Function || HTMLInputElement,
      required: true,
    },
    lat: {
      type: Number,
      default: 31.530443164751656,
    },
    lng: {
      type: Number,
      default: 74.37880084073794,
    },
    zoomLevel: {
      type: Number,
      default: 10,
    },
  },
  data() {
    return {
      marker: [],
      markers: [],
      map: '',
      circle: {},
      selectedRadius: 0,
      center: { lat: this.lat, lng: this.lng },
      zoomLevelLocal: this.zoomLevel,
      locationAddress: '',
    }
  },
  watch: {
    locationAddress() {
      this.$emit('locationAddressSetFunc', this.locationAddress)
    },
    zoomLevel: {
      async handler() {
        this.center = {
          lat: this.lat, lng: this.lng,
        }
        this.zoomLevelLocal = this.zoomLevel
        await this.initMap()
      },
    },
  },
  mounted() {
    this.initMap()
  },
  methods: {
    selectedRadiusFunc(value) {
      this.selectedRadius = value
      this.circle.setMap(null)
      this.circleFunc()
      this.distanceChanged(this.selectedRadius)
    },
    initMap() {
      // eslint-disable-next-line no-undef
      this.map = new google.maps.Map(document.getElementById('map'), {
        center: { lat: this.center.lat, lng: this.center.lng },
        zoom: this.zoomLevelLocal,
        mapTypeId: 'roadmap',
      })
      //   // Create the search box and link it to the UI element.
      const input = document.getElementById(this.id)
      // eslint-disable-next-line no-undef
      const searchBox = new google.maps.places.SearchBox(input)
      this.map.addListener('bounds_changed', () => {
        searchBox.setBounds(this.map.getBounds())
      })
      // Listen for the event fired when the user selects a prediction and retrieve
      // more details for that place.
      searchBox.addListener('places_changed', () => {
        const places = searchBox.getPlaces()
        // For each place, get the icon, name and location.
        // eslint-disable-next-line no-undef
        const bounds = new google.maps.LatLngBounds()

        places.forEach(place => {
          if (!place.geometry || !place.geometry.location) {
            console.log('Returned place contains no geometry')
            return
          }
          if (place.geometry.viewport) {
            // Only geocodes have viewport.
            bounds.union(place.geometry.viewport)
          } else {
            bounds.extend(place.geometry.location)
          }
          this.center.lat = place.geometry.location.lat()
          this.center.lng = place.geometry.location.lng()
          this.locationAddress = place.formatted_address
          this.$emit('circleCenterSetFunc', this.center)
          this.$emit('locationAddressSetFunc', this.locationAddress)
        })
        this.map.fitBounds(bounds)
        this.map.setZoom(11)
      })
      this.drawCircle(this.center.lat, this.center.lng)
      // eslint-disable-next-line no-undef
      google.maps.event.addListener(this.circle, 'dragend', () => {
        this.center.lat = this.circle.getCenter().lat()
        this.center.lng = this.circle.getCenter().lng()
      })
      // eslint-disable-next-line no-undef
      google.maps.event.addListener(this.circle, 'radius_changed', () => {
        this.selectedRadius = this.circle.getRadius()
      })
    },
    circleFunc() {
      this.drawCircle(this.center.lat, this.center.lng)
      // eslint-disable-next-line no-undef
      google.maps.event.addListener(this.circle, 'dragend', () => {
        this.center.lat = this.circle.getCenter().lat()
        this.center.lng = this.circle.getCenter().lng()
      })
      // eslint-disable-next-line no-undef
      google.maps.event.addListener(this.circle, 'radius_changed', () => {
        this.selectedRadius = this.circle.getRadius()
      })
    },
    distanceChanged(val) {
      this.circle.set('radius', Number(val * 1000))
    },
    changeHandler() {
      this.circle.setMap(null)
      this.markers.forEach(marker => {
        marker.setMap(null)
      })
      this.selectedRadius = 0
    },
    devicesMarkerFunc(devicesPaths) {
      this.markers.forEach(marker => {
        marker.setMap(null)
      })
      this.markers = []
      // eslint-disable-next-line no-undef,no-plusplus
      for (let i = 0; i < devicesPaths.length; i++) {
        // eslint-disable-next-line no-undef
        this.markers.push(new google.maps.Marker({
          position: devicesPaths[i],
          title: devicesPaths[i].name,
        }))
        // this.marker.setMap(this.map)
      }
      this.markers.forEach(marker => {
        marker.setMap(this.map)
      })
    },
    drawCircle(lat, lng) {
      // eslint-disable-next-line no-undef
      this.circle = new google.maps.Circle({
        strokeColor: '#ed1c24',
        strokeOpacity: 0.2,
        strokeWeight: 2,
        fillColor: '#ed1c24',
        fillOpacity: 0.4,
        map: this.map,
        zIndex: 1,
        center: { lat, lng },
        radius: this.selectedRadius * 1000,
      })
      this.circle.setMap(this.map)
    },
  },
}
</script>

<style lang="scss">
@import "src/assets/scss/style";
.pac-container {
  z-index: 5 !important;
}
.map-view-com {
  #map {
    height: 100%;
  }

  /* Optional: Makes the sample page fill the window. */
  html,
  body {
    height: 100%;
    margin: 0;
    padding: 0;
  }
}
</style>
