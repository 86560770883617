<template>
  <div class="banner-ads-media mt-2">
    <b-row>
      <b-col
        cols
        class="text-align-center mx-auto"
      >
        <b-link
          class="mediaText"
        >
          Media
        </b-link>
      </b-col>
    </b-row>
    <b-tabs
      class="tabClass"
      align="center"
    >
      <b-card
        style="margin-bottom: 0 !important;"
        class="card2"
      >
        <b-tab
          active
          title="Images"
        >
          <b-card class="card2 mt-2rem text-center green flex-grow-0 flex-shrink-0 position-relative">
            <div class="mx-1">
              <b-row>
                <b-col
                  cols="12"
                  md="6"
                >
                  <div
                    class="d-flex align-items-center justify-content-start"
                  >
                    <b-form-input
                      v-model="searchQueryOfImage"
                      class="d-inline-block mr-1"
                      placeholder="Search..."
                      @keyup.enter="getPaginatedImages()"
                    />
                    <b-button
                      variant="primary"
                      @click="getPaginatedImages()"
                    >
                      Search
                    </b-button>
                  </div>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                  class="d-flex align-items-center justify-content-end mb-1 mb-md-0 font-weight-bold"
                >
                  <div class="img-text-center">
                    Add Images from here
                  </div>
                </b-col>
              </b-row>
            </div>
            <b-row class="pl-1">
              <b-skeleton-wrapper
                :loading="mediaLoading"
                class="pt-1 w-100 mx-2"
              >
                <template #loading>
                  <b-skeleton width="100%" />
                  <b-skeleton width="75%" />
                  <b-skeleton width="50%" />
                </template>
                <div
                  v-for="(data,index) in media.image.data"
                  :key="index"
                  class="my-1 rowCard"
                  :perPage="media.image.pagination.perPage"
                >
                  <b-media
                    vertical-align="center"
                    class="addDevice"
                  >
                    <template #aside>
                      <b-img
                        :src="getThumbnailOfFile(data)"
                        class="image"
                        rounded
                        style="margin-left: -1.2rem"
                      />
                    </template>
                    <div class="d-flex justify-content-center align-items-center">
                      <div class="row">
                        <div class="text col col-12 d-flex justify-content-start name-div-class">
                          <div class="text-truncate">
                            <span
                              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                              v-b-tooltip.hover.top="data.name"
                              class="mb-0 profileText"
                            >
                              {{ data.name }}
                            </span>
                          </div>
                        </div>
                        <div class="text-nowrap text col col-12 d-flex justify-content-start">
                          {{ data.duration }} sec
                        </div>
                      </div>
                      <div class="d-flex float-right mb-1">
                        <feather-icon
                          icon="PlusIcon"
                          class="cursor-pointer plusIcon"
                          size="16"
                          @click="addMediaToCampaignFile(data)"
                        />
                      </div>
                    </div>
                  </b-media>
                </div>
              </b-skeleton-wrapper>
            </b-row>
          </b-card>
          <!-- Pagination -->
          <div class="mr-2 pagination-class">
            <b-col
              cols="12"
              class="
              d-flex
              align-items-baseline justify-content-end justify-content-sm-end "
            >
              <b-pagination
                v-model="media.image.pagination.currentPage"
                :total-rows="media.image.totalRecords"
                :per-page="media.image.pagination.perPage"
                class="mb-0 mt-sm-0"
                aria-controls="media-table"
                size="sm"
                pills
                @change="getPaginatedImages"
              />
            </b-col>
          </div>
        <!-- </b-card-text> -->
        </b-tab>
        <b-tab
          title="Videos"
        >
          <b-card class="card2 mt-2rem text-center green flex-grow-0 flex-shrink-0 position-relative">
            <div class="mx-1">
              <b-row>
                <b-col
                  cols="12"
                  md="6"
                >
                  <div
                    class="d-flex align-items-center justify-content-start"
                  >
                    <b-form-input
                      v-model="searchQueryOfVideo"
                      class="d-inline-block mr-1"
                      placeholder="Search..."
                    />
                    <b-button
                      variant="primary"
                      @click="getPaginatedVideos()"
                    >
                      Search
                    </b-button>
                  </div>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                  class="d-flex align-items-center justify-content-end mb-1 mb-md-0 font-weight-bold"
                >
                  <div class="img-text-center">
                    Add Videos from here
                  </div>
                </b-col>
              </b-row>
            </div>
            <b-row class="pl-1">
              <b-skeleton-wrapper
                :loading="mediaLoading"
                class="pt-1 w-100"
              >
                <template #loading>
                  <b-skeleton width="100%" />
                  <b-skeleton width="75%" />
                  <b-skeleton width="50%" />
                </template>
                <div
                  v-for="(data,index) in media.video.data"
                  :key="index"
                  class="my-1 rowCard"
                >
                  <b-media
                    vertical-align="center"
                    class="addDevice"
                  >
                    <template #aside>
                      <b-img
                        :src="getThumbnailOfFile(data)"
                        class="image"
                        rounded
                        style="margin-left: -1.2rem"
                      />
                    </template>
                    <div class="d-flex justify-content-center align-items-center">
                      <div class="row">
                        <div class="text col col-12 d-flex justify-content-start name-div-class">
                          <div class="text-truncate">
                            <span
                              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                              v-b-tooltip.hover.top="data.name"
                              class="mb-0 profileText"
                            >
                              {{ data.name }}
                            </span>
                          </div>
                        </div>
                        <div class="text-nowrap text col d-flex justify-content-start">
                          {{ data.duration }} sec
                        </div>
                      </div>
                      <div class="d-flex float-right mb-1">
                        <feather-icon
                          icon="PlusIcon"
                          class="cursor-pointer plusIcon"
                          size="16"
                          @click="addMediaToCampaignFile(data)"
                        />
                      </div>
                    </div>
                  </b-media>
                </div>
              </b-skeleton-wrapper>
            </b-row>
          </b-card>
          <!-- Pagination -->
          <div class="mr-2 pagination-class">
            <b-col
              cols="12"
              class="
              d-flex
              align-items-baseline justify-content-end justify-content-sm-end "
            >
              <b-pagination
                v-model="media.video.pagination.currentPage"
                :total-rows="media.video.totalRecords"
                :per-page="media.video.pagination.perPage"
                class="mb-0 mt-sm-0"
                aria-controls="media-table"
                size="sm"
                pills
                @change="getPaginatedVideos"
              />
            </b-col>
          </div>
        </b-tab>
      </b-card>
    </b-tabs>
  </div>
</template>

<script>
import {
  BTabs, BTab, VBTooltip, BPagination, BMedia, BImg, BRow, BCol, BFormInput, BButton, BCard, BSkeletonWrapper, BSkeleton, BLink,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import MediaType from '@/common/enums/mediaTypeEnum'
import { getThumbnailOfFile } from '@/common/global/functions'

export default {
  name: 'BannerAdsMedia',
  components: {
    BPagination,
    BTabs,
    BTab,
    BMedia,
    BImg,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BCard,
    BSkeletonWrapper,
    BSkeleton,
    BLink,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    maxTimeLimit: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      mediaLoading: false,
      videoLoading: false,
      searchQueryOfImage: '',
      searchQueryOfVideo: '',
      media: {
        video: {
          data: [],
          totalRecords: 0,
          pagination: {
            perPage: 5,
            currentPage: 1,
          },
        },
        image: {
          data: [],
          totalRecords: 0,
          pagination: {
            perPage: 5,
            currentPage: 1,
          },
        },
      },
      allMediaImages: [],
      allMediaVideo: [],
    }
  },
  mounted() {
    this.getPaginatedImages()
    this.getPaginatedVideos()
  },
  methods: {
    getThumbnailOfFile(file) {
      return getThumbnailOfFile(file)
    },
    addMediaToCampaignFile(mediaObject) {
      if ((this.$parent.$refs['add-media-cam-ref'].durationTotal + mediaObject.duration) <= this.maxTimeLimit) {
        this.$emit('addMediaToCampaign', mediaObject)
      } else {
        this.$swal(`Maximum time limit of media is ${this.maxTimeLimit} Sec`)
      }
    },
    getPaginatedVideos(offset = null) {
      const offsetLocal = offset ? offset - 1 : this.media.video.pagination.currentPage - 1
      this.mediaLoading = true
      this.$axios
        .get(`media?offset=${offsetLocal}&limit=${this.media.video.pagination.perPage}&type_main=${MediaType.VIDEO}&search_query=${this.searchQueryOfVideo}`)
        .then(({ data: { data } }) => {
          this.media.video.data = data.results
          this.media.video.totalRecords = data.total
          this.mediaLoading = false
        }).catch(error => ({ error }))
    },
    getPaginatedImages(offset = null) {
      const offsetLocal = offset ? offset - 1 : this.media.video.pagination.currentPage - 1
      this.mediaLoading = true
      this.$axios
        .get(`media?offset=${offsetLocal}&limit=${this.media.image.pagination.perPage}&type_main=${MediaType.IMAGE}&search_query=${this.searchQueryOfImage}`)
        .then(({ data: { data } }) => {
          this.media.image.data = data.results
          this.media.image.totalRecords = data.total
          this.mediaLoading = false
        }).catch(error => ({ error }))
    },
  },
}
</script>

<style scoped lang="scss">
.banner-ads-media{
  .add_campaign {
    .search {
      height: 42px !important;
      .vs__search, .vs__search:focus{
        height:36px !important;
      }
      .vs__dropdown-menu {
        max-height: 80px !important;
        overflow-y: auto !important;
      }
      .vs__dropdown-toggle{
        max-height: 60px !important;
        overflow-y: auto !important;
      }
    }
  }
  .tab-content{
    .tab-pane{
      max-height: 170px;
      //overflow-y: auto;
      //overflow-x: hidden;
    }
  }
  @media (max-width: 1325px) {
    .tab-content{
      .tab-pane{
        max-height: 210px;
        overflow-y: auto;
        overflow-x: hidden;
      }
    }
  }
  @media (max-width: 1199px) {
    .tab-content{
      .tab-pane{
        max-height: 170px;
        overflow-y: hidden;
        //overflow-x: hidden;
      }
    }
  }
  @media (max-width: 1062px) {
    .tab-content{
      .tab-pane{
        max-height: 170px;
        overflow-y: auto;
        overflow-x: hidden;
      }
    }
  }
  .heading{
    font-size: 20px;
    line-height: 44px;
    color: #1f58b5;
    font-weight: 600;
    font-family: "Montserrat";
    text-align: center;
  }
  .mediaText{
    font-size: 14px;
    line-height: 22px;
    color: #323232;
    font-weight: 600;
    font-family: "Montserrat";
    display: flex;
    justify-content: center;
  }
  .card2{
    width: 100%;
    //height: 185px;
    border-radius: 10px;
    background-color: #eeeeee;
    margin-right: 41px;
    //overflow-y: scroll;
  }
  .addDevice{
    width: 171px;
    height: 65px;
    border-radius: 10px;
    background-color: #ffffff;
    padding: 25px;
  }
  .image {
    width: 32px;
    height: 32px;
    border: 2px solid#ececec;
    border-radius: 5px !important;
    box-shadow: 0 1px 7px#0000002e;
  }
  .text{
    font-size: 12px;
    color: #5e5873;
    font-weight: 500;
    font-family: "Montserrat";
  }
  .ml-0{
    font-size: 10px;
    color: #6e6b7b;
    font-weight: 400;
    font-family: "Montserrat";
  }
  .card3{
    width: 100%;
    background-color: #F2FFFF;
    overflow-y: scroll;
    min-height: 150px;
    opacity: 6;
  }
  .card3device{
    width: 151px;
    height: 60px;
    border-radius: 10px;
    padding-left: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .choose{
    display: flex;
    background-color: #e0dee1 !important;
    color: #6e6b7b !important;
    border-color: #e0dee1 !important;
  }
  .cross{
    position: absolute;
    top: -7px;
    left: -5px;
  }
  .plusIcon{
    margin-top: 1.2rem;
    margin-left: 1rem;
  }
  .rowCard{
    flex-grow: 0;
    margin-left: 0px !important;
    margin-right: 0px !important;
    padding-left: 8px;
  }
  .modal1{
    padding: 0 !important;
  }
  .loaderClass{
    display: block;
    padding-left: 5px;
  }
  .pagination-class{
    position: absolute;
    bottom: 15px;
    right: 0px;
  }
  .name-div-class{
    max-width: 100px;
  }
  @media (max-width: 768px) {
    .img-text-center{
      margin: 0 auto;
      padding-top: 10px;
    }
  }
}
</style>
